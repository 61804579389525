module.exports = require('deepmerge')(require('./config.default.js'), {
  native: {
    appleTeamId: '2BAP3P29V2',
    name: {
      default: 'Haussmann Staging'
    },
    id: 'com.printemps.haussmann.staging',
    appleAppId: '1012404510',
    androidSigning: {
      keyAlias: "Haussmann",
      keyStorePassword: "wui1ieph7EiC8OL3"
    }
  },
  web: {
    url: "ph-ecran.ms-staging.mobile-spot.com"
  }
});