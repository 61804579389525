'use strict';

var defaultConf = require('./config-default');

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'staging',
  BO_URL: 'boph3.8-data-dev.mobile-spot.com/',
  // no trailing '/'
  BO_PATH: '/home/www/printemps/boph/boph3.8-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  BO_SSH_USER: "www",
  BO_SSH_PORT: '22',
  DEPLOY_PATH: '~/ph-ecran-staging.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: '',
  // TODO
  projectId: 217901982433,
  // GCM/FCM send id
  appId: '154FE-B7042',
  // pushwoosh appid
  timerScreenSaver: 120,
  // in second
  randomTimeInterval: {
    min: 0,
    max: 300
  }
});